import { defineStore } from 'pinia';
import axios, { type AxiosResponse } from 'axios';
import { isString, type Nil } from '@mcwd/typescript-type-guards';
import { DateTime as LuxonDateTime } from 'luxon';

export interface Resource { 
    id: string,
    title: string,
    description: string,
    hubType: string,
    assetType: string,
    variation: string,
    language: string,
    landingPageUrl: string,
    topics?: string[],
    solution?: string,
    industry?: string,
    thumbnail?: string,
    thumbnailAltText?: string,
    startDateAndTime?: string,
    startDateAndTimeFormatted?: {
        date: string, time: string
    } | null,
    eventHeading?: string,
    cardCtaLabel?: string,
    hubExpiration?: Date | null,
    isExternal: boolean,
    heading: string,
}

type RawResource = Omit<Resource, "hubExpiration"> & { hubExpiration?: string };

interface TimeZoneData {
    locale: string,
    timeZone: string
}

const getTimeZone = (() => {
    const langToTimeZone = {
        "en-US": { locale: "en-us", timeZone: "EST5EDT" },
        "en-AU": { locale: "en-au", timeZone: "Australia/Sydney" },
        "en-GB": { locale: "en-gb", timeZone: "Europe/London" },
        "de-DE": { locale: "de-de", timeZone: "Europe/Berlin" },
        "fr-FR": { locale: "en-gb", timeZone: "Europe/Paris" },
        "ja-JP": { locale: "ja-jp", timeZone: "Asia/Tokyo" },
        "zh-CN": { locale: "zh-CN", timeZone: "GMT" },
        "zh-TW": { locale: "zh-TW", timeZone: "GMT" },
    };
    type LangToTimeZone = typeof langToTimeZone;
    return (lang: keyof LangToTimeZone | string, defaultValue: TimeZoneData = langToTimeZone["en-US"]): TimeZoneData => {
        return ((lang in langToTimeZone)? langToTimeZone[lang] : defaultValue) as TimeZoneData;
    };
})();

function getEventTime(rawTime: string) {
    const timeZoneInfo = getTimeZone(window.AppState.GetCurrentLanguageName());
    const jsDate = new Date(rawTime);
    const time: string = LuxonDateTime.fromJSDate(jsDate, { zone: timeZoneInfo.timeZone }).toFormat('t ZZZZ');
    const date: string = LuxonDateTime.fromJSDate(jsDate, { zone: timeZoneInfo.timeZone }).toFormat('d LLL yyyy');
    return { date, time };
}

interface ResourceStoreState {
    resources?: Array<Resource> | Nil,
    topThreeIds?: [string, string, string] | Nil,
    topThreeResources?: [Resource, Resource, Resource] | Nil,
    topThreeResourcesAll?: [] | Nil,
    loading: boolean,
    error?: Error | Nil
}

async function getResourcesFromApi(url: string) {
    return await axios.get(url, { timeout: 60000 })
        .then((result: AxiosResponse<RawResource[], unknown>) => {
            return result.data.map(rawRes => {
                const { hubExpiration: hubExpirationStr, startDateAndTime, ...remainingProps } = rawRes;
                const hasHubExpiration = isString(hubExpirationStr) && hubExpirationStr.trim().length > 0;
                const hubExpiration = hasHubExpiration ? new Date(hubExpirationStr) : null;
                
                const startDateAndTimeFormatted = isString(startDateAndTime) ? getEventTime(startDateAndTime) : null;
                return { ...remainingProps, hubExpiration, startDateAndTime, startDateAndTimeFormatted } as Resource;
            });
        });
}

export const useResouceCenterStore = defineStore('resourceCenterStore', {
    state: () => {
        return {
            resources: null,
            topThreeIds: null,
            topThreeResources: null,
            topThreeResourcesAll: null,
            loading: false,
            error: null
        } as ResourceStoreState;
    },
    actions: {
        async fetchResources(this: ResourceStoreState) {
            const url = `${window.AppState.StaticSiteUrlWithDevPrefix}/json/landing-page-results.json`;
            this.loading = true;
            try {
                this.resources = await getResourcesFromApi(url);
            }
            catch(err) {
                console.error(err);
                this.error = (err instanceof Error) ? err : new Error((err as object)?.toString?.() ?? JSON.stringify(err) ?? "Unknown Error");
            }
            

            const resources = this.resources;
            this.topThreeIds = ((window as any).topThreeIds as [string, string, string] | undefined);
            let topThreeResources: [Resource | null, Resource | null, Resource | null] | null = null;
            let topThreeResourcesAll: [] | null = null;
            // console.warn('TopThreeIds: ',this.topThreeIds);
            if (resources && this.topThreeIds){
                topThreeResources = this.topThreeIds.map((id: string) => {
                    return resources.find(r => r.id === id) ?? null;
                }) as [Resource | null, Resource | null, Resource | null];

                topThreeResourcesAll = this.topThreeIds.map((id: string) => {
                    const el = resources.filter(r => r.id === id) ?? null;
                    return el;
                }) as [];

                if (topThreeResources.some(r => r === null)) {
                    this.topThreeResources = null;
                }
                else {
                    this.topThreeResources = topThreeResources as [Resource, Resource, Resource];
                }

                // console.warn('topThreeResourcesAll: ',topThreeResourcesAll);
                if (topThreeResourcesAll.some(r => r === null)) {
                    this.topThreeResourcesAll = null;
                }
                else {
                    this.topThreeResourcesAll = topThreeResourcesAll as [];
                }
            }
            else {
                this.topThreeResources = null;
            }
        }
    }
});